<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <Header />
      <!-- /Brand logo-->
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <CoverImage />
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class=" align-items-center auth-bg px-2 p-lg-5"
      >
        <div class="row">
          <div class=" mx-auto col-12 ">
            <MediaIcon />
          </div>
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto margin-top-icon-sm"
          >
            <b-card-title class="mb-1">
              OTP 🔒
            </b-card-title>
            <b-card-text
              show
              class="mb-2"
            >
              <b-alert
                v-if="verificationData && (verificationData.phone || verificationData.email) && verificationData.verificationType"
                show
                variant="success"
                class="p-1"
              >
                Verification code send <p v-if="verificationData.email && (verificationData.verificationType==1|| verificationData.verificationType==3)">Your Email(<b> {{ verificationData.email }} </b>)
                  <b-link
                    :disabled="isLoading"
                    @click="onResendEmail"
                  > Resend
                  </b-link>
                </p>
                <p v-if="verificationData.phone && (verificationData.verificationType==1|| verificationData.verificationType==2)"> and Phone(<b> {{ verificationData.phone }} </b>)
                  <b-link
                    v-if="!verificationData.isResetSMS"
                    :disabled="isLoading"
                    @click="onResendPhone"
                  > Resend
                  </b-link>
                </p>
                Code expired <b>{{ verificationData.expired_date }}</b>
              </b-alert>
            </b-card-text>

            <!-- form -->
            <validation-observer ref="forgotPasswordForm">
              <b-form
                class="auth-forgot-password-form mt-2"
                @submit.prevent="validationForgotPasswordOTPVerifyForm"
              >
                <!-- username -->
                <b-form-group
                  label="OTP Code"
                  label-for="forgot-password-otp"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="otpCode"
                    vid="otpCode"
                    rules="required"
                  >
                    <b-form-input
                      id="forgot-password-otp"
                      v-model="otpCode"
                      :state="errors.length > 0 ? false : null"
                      name="forgot-password-otp"
                      placeholder="Ex: 123456"
                      type="number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="isLoading"
                >
                  Verify OTP
                </b-button>
              </b-form>
            </validation-observer>

            <p class="text-center mt-2">
              <b-link
                :disabled="isLoading"
                @click="onBackToLogin"
              >
                <feather-icon icon="ChevronLeftIcon" /> Back to login
              </b-link>
            </p>
          </b-col>
        </div>
      </b-col>
      <!-- /Forgot password-->
      <!-- /footer-->
      <b-col
        lg="12"
        class="mt-auto"
      >
        <Footer />
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  // BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BAlert,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AuthService from '@/@service/api/Auth.service'
import LocalStorageService from '@/@service/utils/LocalStorage.service'

import Header from '@/@core/components/landding-page/Header.vue'
import MediaIcon from '@/@core/components/landding-page/MediaIcon.vue'
import Footer from '@/@core/components/landding-page/Footer.vue'
import CoverImage from '@/@core/components/landding-page/CoverImage.vue'
import FemsToastrService from '@/@service/utils/FemsToastr.service'

export default {
  components: {
    // VuexyLogo,
    BRow,
    BCol,
    BLink,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    BAlert,
    ValidationProvider,
    ValidationObserver,
    Header,
    Footer,
    MediaIcon,
    CoverImage,
  },
  data() {
    return {
      userName: LocalStorageService.get('user_username'),
      verificationData: JSON.parse(LocalStorageService.get('verificationData')),

      getPageData: JSON.parse(LocalStorageService.get('pageData')) || '',
      pageData: {},
      otpCode: '',
      isLoading: false,
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    if (!this.userName || !this.verificationData || (!this.verificationData.phone && !this.verificationData.email)) {
      this.$router.push({ name: 'auth-login' })
    }
    if (this.getPageData) {
      this.pageData = this.getPageData
    } else {
      this.$router.push({ name: 'auth-login' })
    }
  },
  methods: {
    validationForgotPasswordOTPVerifyForm() {
      this.$refs.forgotPasswordForm.validate().then(success => {
        if (success && this.userName) {
          this.isLoading = true
          AuthService.forgotPasswordResetOTPVerify({
            user_username: this.userName,
            code: this.otpCode,
          }).then(
            res => {
              const response = res.data
              this.isLoading = false

              if (response.otpIsVerified && this.userName) {
                // Fems Toastr Service
                FemsToastrService.success(response.message || 'Already send OTP please enter the OTP.')
                this.$router.replace({ name: 'password.reset' })
              }

              if (!response.status) {
                this.$refs.forgotPasswordForm.setErrors({ userName: [response.message] })

                FemsToastrService.error(response.message || response.message || 'Something went wrong')
              }
            },
          ).catch(error => {
            this.isLoading = false
            // Fems Toastr Service
            FemsToastrService.error(error.message || 'Something went wrong')
          })
        }
      })
    },
    onBackToLogin() {
      LocalStorageService.clear()
      this.$router.push({ name: 'auth-login' })
    },
    onResendEmail() {
      if (this.userName) {
        this.isLoading = true
        LocalStorageService.delete('verificationData')
        AuthService.emailResendOTP({
          user_username: this.userName,
        }).then(res => {
          const response = res.data
          this.isLoading = false
          if (response.isOtpVerification) {
            const data = response?.data
            LocalStorageService.set('verificationData', JSON.stringify(data))
            this.verificationData = data
            // Fems Toastr Service
            FemsToastrService.success(response.message || 'Already send OTP please enter the OTP.')
          }
        }).catch(error => {
          console.log(error)
          this.isLoading = false
        })
      } else {
        this.$router.push({ name: 'auth-login' })
      }
    },
    onResendPhone() {
      if (this.userName) {
        this.isLoading = true
        LocalStorageService.delete('verificationData')
        AuthService.phoneResendOTP({
          user_username: this.userName,
        }).then(res => {
          const response = res.data
          this.isLoading = false
          if (response.isOtpVerification) {
            const data = response?.data
            LocalStorageService.set('verificationData', JSON.stringify(data))
            this.verificationData = data
            // Fems Toastr Service
            FemsToastrService.success(response.message || 'Already send OTP please enter the OTP.')
          }
          if (response.isSMSBLock) {
            LocalStorageService.delete('verificationData')
            LocalStorageService.delete('user_username')
            this.$router.push({ name: 'auth-login' })
            // Fems Toastr Service
            FemsToastrService.error(response.message || 'Something went wrong')
          }
        }).catch(
          error => {
          // Fems Toastr Service
            FemsToastrService.error(error.message || 'Something went wrong')

            this.isLoading = false
          },
        )
      } else {
        LocalStorageService.clear()
        this.$router.push({ name: 'auth-login' })
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
